
import { defineAsyncComponent, defineComponent } from "@vue/runtime-core";
import { getRole } from "../helpers";

export default defineComponent({
    components: {
        ResultLinks: defineAsyncComponent(async () => {
            const { appMode } = await import('@/appModes')
            return import(`@/${appMode}/ResultLinks.vue`)
        })
    },
    computed: { role: getRole },
})
