<template>
    <teleport to='#navSlot'>
        <div :style='{ display: "flex" }' v-if='!["viewer", null].includes(role)'>
            <router-link to='/admin'>פעולות</router-link>
            <result-links />
            <router-link to='/admin/did-not-fill'>לא מילאו</router-link>
            <router-link to='/admin/comments' v-if='role === "admin"'>הערות</router-link>
        </div>
    </teleport>
    <router-view />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "@vue/runtime-core";
import { getRole } from "../helpers";

export default defineComponent({
    components: {
        ResultLinks: defineAsyncComponent(async () => {
            const { appMode } = await import('@/appModes')
            return import(`@/${appMode}/ResultLinks.vue`)
        })
    },
    computed: { role: getRole },
})
</script>